
            
            import { runInjectionScript, swapDocument } from "@clerk/astro/internal";
            import { navigate, transitionEnabledOnThisPage } from "astro:transitions/client";

            if (transitionEnabledOnThisPage()) {
              document.addEventListener('astro:before-swap', (e) => {
                const clerkComponents = document.querySelector('#clerk-components');
                // Keep the div element added by Clerk
                if (clerkComponents) {
                  const clonedEl = clerkComponents.cloneNode(true);
                  e.newDocument.body.appendChild(clonedEl);
                }

                e.swap = () => swapDocument(e.newDocument);
              });

              document.addEventListener('astro:page-load', async (e) => {
                await runInjectionScript({
                  ...{"clerkJSVariant":"headless","sdkMetadata":{"version":"1.4.2","name":"@clerk/astro","environment":"production"}},
                  routerPush: navigate,
                  routerReplace: (url) => navigate(url, { history: 'replace' }),
                });
              })
            } else {
              await runInjectionScript({"clerkJSVariant":"headless","sdkMetadata":{"version":"1.4.2","name":"@clerk/astro","environment":"production"}});
            }
import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: import.meta.env.PUBLIC_SENTRY_DSN,
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()